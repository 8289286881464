import React from 'react';
import PropTypes from 'prop-types';

import {
  CardDetailsContainer,
  CardDetailsContent,
  CardTitleContainer,
  CardTitleHeading,
  CardTitleImage,
  CardTitleMainHeading,
  CardTitleSubheading,
  InfoText,
  InfoTextWrapper,
} from '@routes/GoodieDetails/GoodieCardDetails/GoodieCardDetails.styled';

import { Hr } from '@common/components/Hr';
import FlipCard from '@common/components/FlipCard';
import { StaticCard } from '@common/components/CardBack/StaticCard';
import { VIP_CARD } from '@common/images';
import { VIPCardBack } from '@common/components/CardBack/VipCardBack';

export function GoodieCardDetails({
  isOpen,
  bottomOverline,
  bottomSubheading,
  topLineHeading,
  topLineSubHeading,
  goodieImageElement,
}) {
  return (
    <CardDetailsContainer>
      <CardDetailsContent>
        <CardTitleContainer>
          <CardTitleImage>{goodieImageElement}</CardTitleImage>
          <CardTitleHeading>
            <CardTitleSubheading>{topLineSubHeading}</CardTitleSubheading>
            <CardTitleMainHeading>{topLineHeading}</CardTitleMainHeading>
          </CardTitleHeading>
        </CardTitleContainer>
        <FlipCard
          isOpen={isOpen}
          imageFront={(
            <StaticCard
              image={{
                src: VIP_CARD,
                alt: 'VIP-Karte',
              }}
            />
          )}
          imageBack={<VIPCardBack />}
        />
        <InfoTextWrapper>
          <InfoText>{bottomOverline}</InfoText>
          {bottomSubheading && <Hr spacings={['scale', 5]} />}
          <InfoText>{bottomSubheading}</InfoText>
        </InfoTextWrapper>
      </CardDetailsContent>
    </CardDetailsContainer>
  );
}

GoodieCardDetails.propTypes = {
  isOpen: PropTypes.bool,
  vipCardRef: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    current: PropTypes.any,
  }),
  goodieImageElement: PropTypes.node,
  topLineHeading: PropTypes.string,
  topLineSubHeading: PropTypes.string,
  bottomOverline: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  bottomSubheading: PropTypes.string,
};

GoodieCardDetails.defaultProps = {
  isOpen: false,
  vipCardRef: undefined,
  goodieImageElement: null,
  topLineHeading: '',
  topLineSubHeading: '',
  bottomOverline: '',
  bottomSubheading: '',
};

export default GoodieCardDetails;
