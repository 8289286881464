import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsUserLogged } from '@selectors/auth';
import { selectBarcodeImage } from '@selectors/barcode';
import { selectCardNr } from '@selectors/customerInfo';
import { fetchBarcode } from '@thunks/barcode';
import { fetchCustomerInfo } from '@thunks/customerInfo';

import { LOGO_CHICOREE_GOLD } from '@common/images';
import { VIPUnderlayIcon } from '@common/components/Icons';

import {
  BarcodeImage,
  BarcodeImageContainer,
  BarcodeNumber,
  BarcodeWrapper,
  LogoImage,
  LogoImageWrapper,
  VIPIconWrapper,
  Wrapper,
} from './VIPCardBack.styled';

const prepareBarcodeNumber = number => {
  if (!number) {
    return '';
  }

  return `${number.slice(0, 1)} ${number.slice(1, 5)} ${number.slice(5, 9)} ${number.slice(9, 13)}`;
};

export function VIPCardBack({ className }) {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsUserLogged);
  const barcodeImageUrl = useSelector(selectBarcodeImage);
  const barcodeNumber = useSelector(selectCardNr);

  useEffect(() => {
    if (isLoggedIn && (!barcodeNumber || !barcodeImageUrl)) {
      dispatch(fetchBarcode());
      dispatch(fetchCustomerInfo());
    }
  }, []);

  return (
    <Wrapper className={className}>
      <LogoImageWrapper>
        <LogoImage
          alt="chicoree logo"
          src={LOGO_CHICOREE_GOLD}
        />
      </LogoImageWrapper>
      <BarcodeWrapper>
        <VIPIconWrapper>
          <VIPUnderlayIcon />
        </VIPIconWrapper>
        <BarcodeImageContainer>
          <BarcodeImage
            alt="barcode"
            src={barcodeImageUrl}
          />
        </BarcodeImageContainer>
      </BarcodeWrapper>
      {barcodeNumber && <BarcodeNumber>{prepareBarcodeNumber(barcodeNumber)}</BarcodeNumber>}
    </Wrapper>
  );
}

VIPCardBack.propTypes = {
  className: PropTypes.string,
};

VIPCardBack.defaultProps = {
  className: undefined,
};

export default VIPCardBack;
